// setups

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 320px;
  position: relative;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-family: $main-font;
}

// tag styles reset
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

p {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}






