//contacts-info
.contacts__map {
  max-height: 475px;
}
.contacts__info {
  padding-top: 86px;
  padding-left: 83px;
  padding-bottom: 62px;
  @include tablets {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: center;
  }
}

.contacts__title {
  margin-bottom: 23px;
  color: #191919;
  font-size: 27px;
  font-weight: 700;
}

.contacts__discription {
  margin-bottom: 56px;
  max-width: 410px;
  color: #191919;
  font-size: 13px;
  font-weight: 400;
  @include tablets {
    margin: 0 auto;
    margin-bottom: 28px;
  }
}

.contacts__info-item {
  display: block;
  text-align: left;
  max-width: 300px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 55px;
  color: #191919;
  font-size: 13px;
  font-weight: 400;
  position: relative;
  @include tablets {
    margin: auto;
  }
  a{
    color: #191919;
  }
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    @include centered(y);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  &--adress {
    &::before {
      background-image: url(../img/decor/adress.png);
    }
  }
  &--phone {
    &::before {
      background-image: url(../img/decor/phone.png);
    }
  }
  &--mail {
    &::before {
      background-image: url(../img/decor/mail.png);
    }
  }
}

//documents
.documents {
  padding-top: 68px;
  padding-bottom: 25px;
  background-color: $accent;
  color: #ffffff;
}

.documents__title {
  margin-bottom: 26px;
  text-align: center;
  font-size: 27px;
  font-weight: 700;
}

.documents__description {
  margin: 0 auto;
  margin-bottom: 37px;
  max-width: 770px;
  font-size: 13px;
  font-weight: 400;
}

.documents__card {
  display: block;
  margin: 0 auto;
  max-width: 170px;
  min-height: 242px;
  border: 2px dashed #ffffff;
  margin-bottom: 26px;
}

.documents__card-inner {
  margin: 8px;
  height: calc(242px - 16px);
  border: 1px solid #ffffff;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    //@include centered();
    top: 50%;
    left: 50%;
    width: 26px;
    height: 4px;
    background-color: #ffffff;
    transform: translate(-50%,-50%);
  }
  &::before {
    content: '';
    position: absolute;
    width: 26px;
    height: 4px;
    top: 50%;
    left: 50%;
    background-color: #ffffff;
    transform-origin: center center;
    transform: translate(-50%,-50%)  rotate(90deg);
    //@include centered();
  }
}
