.footer {
  margin-top: auto;
  padding-top: 50px;
  padding-bottom: 30px;
  background-color: #313030;
  color: white;
  @include tablets {
    padding-top: 25px;
    padding-bottom: 15px;
  }
}

.footer__info {
  font-size: 17px;
  font-weight: 300;
  line-height: 30px;
  @include tablets {
    text-align: center;
  }
}

.footer__center {
  text-align: center;
}

.footer__logo {
  margin-bottom: 30px;
}

.footer__slogan {
  margin: 0 auto;
  margin-bottom: 31px;
  max-width: 360px;
  font-family: $second-font;
  font-size: 23px;
  font-weight: 400;
}

.footer__links {
  display: flex;
  justify-content: center;
  @include tablets {
    margin-bottom: 15px;
    flex-direction: column;
  }
}

.footer__item {
  font-size: 13px;
  font-weight: 400;
  line-height: 30px;
  text-decoration: underline;
  color: #ffffff;
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
  &:not(:last-child) {
    padding-right: 12px;
    position: relative;
    &::after {
      position: absolute;
      content: '|';
      right: 4px;
      @include tablets {
        content: '';
      }
    }
  }
}

.footer__contacts {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @include tablets {
    margin-bottom: 15px;
    align-items: center;
  }
  @include phones {
    margin-top: 15px;
  }
}

.footer__info-item {
  font-size: 17px;
  font-weight: 400;
  line-height: 30px;
  color: #ffffff;
  position: relative;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
  &::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #fff;
    background-size: cover;
  }

}

.footer__info-item--mail {
  padding-left: 25px;
  &::before {
    mask-image: url('../img/decor/icons/mail.svg');

  }
}

.footer__info-item--phone {
  padding-left: 45px;
  &::before {
    mask-image: url('../img/decor/icons/phone.svg');
    transform: translateY(-50%) rotate(90deg);
  }
}

#commercialProposal,
#callBack {
  .modal-content {
    border-radius: 0;
  }
  .modal-dialog {
    max-width: 430px;
    border: 3px solid $accent;
  }
  .modal-body {
    padding: 40px 30px;
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: white;

  }
}

.commertialPopUp__form {
  display: flex;
  flex-direction: column;
  hr {
    margin: 0;
    margin-top: 32px;
    margin-bottom: 32px;
    height: 1px;
    background-color: gainsboro;
  }
  input {
    padding: 15px 35px;
    margin-bottom: 8px;
    color: #676767;
    font-size: 14px;
    font-style: italic;
    border: 1px solid black;
  }
}

.commertialPopUp__title {
  color: #2a2a2a;
  font-family: $second-font;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  &--main {
    margin-bottom: 35px;
  }
}

.commertialPopUp__label {
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  padding-left: 48px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  color: #2a2a2a;
  cursor: pointer;
  &--low {
    font-size: 13px;
    font-weight: 400;
    span {
      border-color: $accent !important;
    }
  }
  input[type=checkbox] {
    display: none;
  }
  & input:checked {
    + span {
      background: url(../img/decor/check.png) center no-repeat $accent;
      background-size: contain;
    }
  }
  span {
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: white;
    border: 1px solid #2a2a2a;
    @include centered(y);
  }
}

.commertialPopUp__button {
  width: 258px;
  margin: 0 auto;
  margin-top: 32px;
  background-color: #e64700;
  color: white;
  font-size: 10px;
  font-weight: 400;
  line-height: 36px;
  text-transform: uppercase;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

