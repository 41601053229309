.header {
  box-shadow: 0 0.1rem 0.68125rem 0.13125rem rgba(0, 1, 1, .08);
  &--mainPage {
    background-color: transparent;
    z-index: 200;
    box-shadow: none;
  }
}

.social {
  padding-top: 12px;
  padding-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  @include laptops {
    justify-content: center;
  }
  &--white {
    .social__item a {
      background: #ffffff;
    }
  }
}

.social__item {
  &:not(:last-child) {
    margin-right: 10px;
  }
  a {
    display: block;
    width: 24px;
    height: 24px;
    font-size: 0;
    //background-size: cover;
    background-color: $accent;
    transition: all .15s ease;
    &:hover {
      transform: scale(1.2);
    }
  }
  .tw {
    mask-image: url(../img/decor/icons/twitter-with-circle.svg)
  }
  .fb {
    mask-image: url(../img/decor/icons/facebook-with-circle.svg)
  }
  .gplus {
    mask-image: url(../img/decor/icons/google+-with-circle.svg)
  }
  .pinterest {
    mask-image: url(../img/decor/icons/pinterest-with-circle.svg);
  }
  .linkedin {
    mask-image: url(../img/decor/icons/linkedin-with-circle.svg)
  }

}

.header__logo {
  display: block;
  width: 208px;
  height: 40px;
  background: url('../img/decor/logo.png') center no-repeat;
  background-size: cover;
  font-size: 0;
  @include tablets {
    margin-bottom: 22px;
  }
}

.header__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include laptops {
    justify-content: center;
  }
  @include tablets {
    flex-direction: column;
    align-items: center;
  }
}

.header__menu {
  display: flex;
  flex-wrap: wrap;
  min-height: 40px;
  align-items: center;
  @include tablets {
    display: none;
    padding-top: 30px;
    position: fixed;
    flex-direction: column;
    //justify-content: center;
    z-index: 204;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .85);
    margin: 0;
    top: 0;
    &--show {
      display: flex;
    }
  }

}

.header__sub-menu,
.sub-men {
  display: none;
}

.header__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  @include laptops {
    flex-direction: row;
  }
  a {
    font-family: $second-font;
    font-size: 17px;
    font-weight: 700;
    color: #1c1c1c;
    text-decoration: none;
  }
}

.header__telephone {
  position: relative;
  padding-right: 26px;
  &:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    right: 0;
    background: url('../img/decor/icons/phone.svg') center no-repeat;
    background-size: contain;
    transform: rotate(90deg);
    @include laptops {
      visibility: hidden;
    }
  }

}

.header__mail {
  position: relative;
  padding-right: 26px;
  &:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    right: 0;
    background: url('../img/decor/icons/mail.svg') center no-repeat;
    background-size: contain;
    @include laptops {
      visibility: hidden;
    }
  }
}

.header__item {
  @include tablets {
    padding-top: 15px;
    padding-bottom: 12px;
  }
  position: relative;
  &:not(:last-child) {
    margin-right: 23px;
    @include tablets {
      margin-right: 0;
    }
  }
  a {
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 13px;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    color: $dark;
    @include tablets {
      font-size: 18px;
      color: $light;
    }
    &:hover {
      color: $accent;
    }
  }
  &:hover .header__sub-menu,
  &:hover .sub-menu {
    display: block;
  }
}

.header__sub-menu,
.sub-menu {
  display: none;
  position: absolute;
  padding: 0 7px;
  top: calc(100%);
  left: -19px;
  width: 280px;
  background-color: rgba(#f6f6f5, 0.85);
  box-shadow: 0 0 9px 1px rgba(3, 7, 8, 0.08);
  text-align: center;
  z-index: 205;
  @include tablets {
    @include centered(x);
    background-color: #f6f6f5;
  }
  .header__item, .menu-item {
    margin: 0;
    font-size: 18px;

    a {
      color: #000000;
    }
    &:not(:last-of-type) {
      border-bottom: 1px solid #191919;
    }
  }
  .header__link, & a {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: 'Myriad Pro', sans-serif;
    font-size: 12px;
    font-weight: 400;
    transition: color .15s linear;
    &:hover {
      color: $accent;
    }
  }
}

.menu-item {
  @extend .header__item;
}

.header__buttons {
  padding-top: 12px;
  padding-bottom: 18px;
  display: flex;
  justify-content: flex-end;
  @include laptops {
    justify-content: center;
  }
}

.header__button {
  margin-left: 8px;
  padding: 8px 24px;
  font-size: 10px;
  font-weight: 300;
  font-family: $main-font;
  line-height: 1;
  border-radius: 10px;
  background-color: $accent;
  text-transform: uppercase;
  color: $light;
  border: none;
  cursor: pointer;
  @include btn-hover($accent);
}

.header__nav-menu {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 29px;
  padding: 11px 8px;
  letter-spacing: 0.2px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  z-index: 500;
  display: none;
  @include tablets {
    display: flex;
  }
  span {
    position: relative;
    display: block;
    width: 30px;
    height: 3px;
    background-color: #000000;
    transition: all 0.25s;
    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: #000000;
      left: 0;
      top: 9px;
      transition: all 0.3s
    }
    &::after {
      top: 18px
    }
  }
  &.on span {
    background-color: transparent;
  }
  &.on span:before {
    transform: rotate(45deg) translate(-1px, 0px);
    background-color: $accent;
  }
  &.on span:after {
    transform: rotate(-45deg) translate(6px, -7px);
    background-color: $accent;
  }
}

.top-mnu {
  display: flex;
  justify-content: flex-end;
  @include tablets {
    justify-content: space-between;
    //margin-bottom: 25px;
  }
}

.slider {
  margin-top: -157px;
  @include laptops {
    margin-top: -212px;
  }
}

.slick-arrow {
  position: absolute;
  width: 34px;
  height: 34px;
  @include centered(y);
  font-size: 0;
  border: 0;
  cursor: pointer;
  @include phones {
    display: none;
  }
}

.slick-next {
  right: 70px;
  background: url(../img/decor/slider-arrow.png) center no-repeat;
  background-size: contain;
}

.slick-prev {
  z-index: 100;
  left: 70px;
  background: url(../img/decor/slider-arrow.png) center no-repeat;
  background-size: contain;
  transform: translateY(-50%) rotate(180deg);
}

.slider__item {
  min-height: 800px;
  height: 95vh;
  background-size: cover;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.slider__title {
  //max-width: 597px;
  padding-bottom: 35px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 25px;
  text-shadow: 0 0 9px rgba(3, 7, 8, 0.13);
  color: #fefefe;
  font-family: $second-font;
  font-size: 55px;
  @include phones {
    font-size: 1.5rem;
  }
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    background-color: #e0af47;
    width: 145px;
    height: 2px;
    @include centered(x)
  }

}

.slider__description {
  max-width: 746px;
  padding-left: 10px;
  padding-right: 10px;
  text-shadow: 0 0 9px rgba(3, 7, 8, 0.13);
  color: #ededed;
  font-family: $main-font;
  font-size: 25px;
  @include phones {
    font-size: 1rem;
  }
  font-weight: 400;
  text-align: center;
}
