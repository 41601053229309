.service {
  padding-top: 55px;
  padding-bottom: 25px;
  @include tablets {
    padding-top: 25px;
    padding-bottom: 0;
  }
}

.service__card {
  display: flex;
  padding: 35px 25px;
  margin-bottom: 25px;
  flex-direction: column;
  min-height: 360px;
  position: relative;
  background-size: cover;
  text-decoration: none;
  &::before {
    content: '';
    position: absolute;
    height: 10px;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: $accent;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($accent, 0.8);
    opacity: 0;
    transition: opacity .25s ease;
  }
  &:hover {
    text-decoration: none;
    &::after {
      opacity: 1;
    }
    .service__description {
      visibility: visible;
    }
  }
}

.service__title {
  margin-top: auto;
  font-family: $second-font;
  font-size: 24px;
  font-weight: 700;
  color: $light;
  text-decoration: none;
  z-index: 100;
}

.service__description {
  font-family: $second-font;
  font-size: 11px;
  font-weight: 400;
  color: $light;
  text-decoration: none;
  z-index: 100;
  visibility: hidden;
  transition: visibility .15s linear;
}

//about
.about {
  padding-top: 28px;
  padding-bottom: 25px;
  color: $light;
  background-color: $accent;
  hr {
    margin: 0 auto;
    max-width: 713px;
    height: 1px;
    background-color: $light;
    border: none;
  }
}

.about__title {
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
}

.about__description {
  padding-top: 20px;
  padding-left: 13px;
  padding-right: 13px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 713px;
  @include tablets {
    //justify-content: center;
  }
}

.about__item {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  @include tablets {
    margin-bottom: 12px;
  }
  &:nth-child(1) {
    padding-left: 73px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 34px;
      height: 35px;
      @include centered(y);
      background-image: url(../img/decor/settings.png);
      @include cover;
      @include retina {
        background-image: url(../img/decor/settings@2x.png)
      }
    }
  }
  &:nth-child(2) {
    padding-left: 55px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      @include centered(y);
      width: 28px;
      height: 35px;
      background-image: url(../img/decor/doc.png);
      @include cover;
      @include retina {
        background-image: url(../img/decor/doc@2x.png)
      }
    }
  }
}

//consultation
.consultation {
  padding-top: 65px;
  padding-bottom: 62px;
  background-image: url(../img/decor/Advice/main.jpg);
  background-size: 100%;
  @include cover;
}

.consultation__inner {
  display: flex;
  justify-content: flex-end;
}

.consultation__title {
  margin-bottom: 24px;
  font-size: 27px;
  font-weight: 700;
  text-align: center;
  color: #191919;
}

.consultation__divider {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @include tablets {
    justify-content: center;
    flex-direction: column;
  }
  input {
    margin-bottom: 13px;
    min-width: 260px;
    padding: 3px 27px;
    font-size: 13px;
    font-weight: 400;
    line-height: 36px;
    background-color: white;
    border: 1px solid $accent;
    @include placecolor(#848282);
    &:not(:last-of-type) {
      margin-right: 13px;
      @include tablets {
        margin-right: 0;
      }
    }
  }
}

.consultation__label {
  position: relative;
  padding-left: 30px;
  font-size: 13px;
  font-weight: 400;
  color: #191919;
  cursor: pointer;
  input[type=checkbox] {
    display: none;
  }
  & input:checked {
    + span {
      background-color: $accent;
    }
  }
  span {
    position: absolute;
    left: 0;
    width: 18px;
    height: 18px;
    background-color: white;
    border: 1px solid #e64700;
    @include centered(y);
  }
}

.consultation__link {
  color: $accent;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.consultation__button {
  margin-left: auto;
  padding: 17px 60px;
  padding-right: 35px;
  max-width: 260px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  background-color: $accent;
  border-radius: 4px;
  border: none;
  color: $light;
  cursor: pointer;
  @include btn-hover($accent);
  @include tablets {
    margin-left: 0;
  }
  span {
    position: relative;
    padding-left: 43px;
    &::after {
      content: '';
      position: absolute;
      mask-image: url(../img/decor/icons/rocket.svg);
      background-color: #fff;
      width: 17px;
      height: 17px;
      left: 0;
      @include centered(y);
    }
  }
}