@font-face {
  font-family: 'Lato';
  src: local('Lato Bold'), local('Lato-Bold'),
  url('../fonts/Lato/hinted-subset-Lato-Bold.woff2') format('woff2'),
  url('../fonts/Lato/hinted-subset-Lato-Bold.woff') format('woff'),
  url('../fonts/Lato/hinted-subset-Lato-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Regular'), local('Lato-Regular'),
  url('../fonts/Lato/hinted-subset-Lato-Regular.woff2') format('woff2'),
  url('../fonts/Lato/hinted-subset-Lato-Regular.woff') format('woff'),
  url('../fonts/Lato/hinted-subset-Lato-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Black'), local('Lato-Black'),
  url('../fonts/Lato/hinted-subset-Lato-Black.woff2') format('woff2'),
  url('../fonts/Lato/hinted-subset-Lato-Black.woff') format('woff'),
  url('../fonts/Lato/hinted-subset-Lato-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: local('Lato Light'), local('Lato-Light'),
  url('../fonts/Lato/hinted-subset-Lato-Light.woff2') format('woff2'),
  url('../fonts/Lato/hinted-subset-Lato-Light.woff') format('woff'),
  url('../fonts/Lato/hinted-subset-Lato-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Minion Pro';
  src: local('Minion Pro Bold'), local('MinionPro-Bold'),
  url('../fonts/MinionPro/hinted-subset-MinionPro-Bold.woff2') format('woff2'),
  url('../fonts/MinionPro/hinted-subset-MinionPro-Bold.woff') format('woff'),
  url('../fonts/MinionPro/hinted-subset-MinionPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Minion Pro';
  src: local('Minion Pro Regular'), local('MinionPro-Regular'),
  url('../fonts/MinionPro/hinted-subset-MinionPro-Regular.woff2') format('woff2'),
  url('../fonts/MinionPro/hinted-subset-MinionPro-Regular.woff') format('woff'),
  url('../fonts/MinionPro/hinted-subset-MinionPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad Pro';
  src: local('Myriad Pro Regular'), local('MyriadPro-Regular'),
  url('../fonts/MyriadPro/hinted-subset-MyriadPro-Regular.woff2') format('woff2'),
  url('../fonts/MyriadPro/hinted-subset-MyriadPro-Regular.woff') format('woff'),
  url('../fonts/MyriadPro/hinted-subset-MyriadPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}