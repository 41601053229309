/* --------- mixins --------- */

@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin inlineblock($valign: top) {
  display: inline-block;
  vertical-align: $valign;
}

@mixin placecolor($color: #000000) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

%ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* --------- centered --------- */

@mixin absolute-center {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}

@mixin centered($axis: 'both') {
  position: absolute;

  @if ($axis == 'both') {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @if $axis == 'x' {
    left: 50%;
    transform: translateX(-50%);
  }

  @if $axis == 'y' {
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin placecolor($color: #000000) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

/* --------- style mixins --------- */

@mixin vertical-gradient($top-color: #ffffff, $bottom-color: #000000) {
  background-color: $top-color;
  background-image: -webkit-gradient(linear, left top, left bottom, from($top-color), to($bottom-color)); /* Chrome, Safari 4+ */
  background-image: -webkit-linear-gradient(top, $top-color, $bottom-color); /* Chrome 10-25, iOS 5+, Safari 5.1+ */
  background-image: -moz-linear-gradient(top, $top-color, $bottom-color); /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, $top-color, $bottom-color); /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, $top-color, $bottom-color); /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr=$top-color, EndColorStr=$bottom-color);
}

@mixin cover() {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* --------- responsive mixins --------- */

@mixin retina {
  @media screen and (-webkit-min-device-pixel-ratio: 1.5),
  screen and (min-resolution: 144dpi) {
    @content;
  }
}

@mixin desktops {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin laptops {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

@mixin tablets {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin phones {
  @media only screen and (max-width: 480px) {
    @content;
  }
}

/* --------- font mixins --------- */
@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;

    @if $asset-pipeline == true {
      src: font-url("#{$file-path}.eot");
      src: font-url("#{$file-path}.eot?#iefix") format("embedded-opentype"), font-url("#{$file-path}.woff") format("woff"), font-url("#{$file-path}.ttf") format("truetype");
    }
    @else {
      src: url("#{$file-path}.eot");
      src: url("#{$file-path}.eot?#iefix") format("embedded-opentype"), url("#{$file-path}.woff") format("woff"), url("#{$file-path}.ttf") format("truetype");
    }
  }
}

@mixin btn-hover($color) {
  transition: background-color .15s ease;
  &:hover {
    background-color: lighten($color, 10%);
  }
  &:active {
    background-color: darken($color, 10%);
  }
}