//banner
.banner {
  display: flex;
  justify-content: center;
  padding-top: 95px;
  padding-bottom: 75px;
  background: url("../img/decor/page1.jpg") center no-repeat;
  background-size: cover;
  border-bottom: 20px solid $accent;
  @include tablets {
    padding-top: 45px;
    padding-bottom: 15px;
  }
}

.banner__title {
  margin: 0;
  //max-width: 834px;
  text-align: center;
  text-shadow: 0 0 9px rgba(3, 7, 8, 0.13);
  color: #fefefe;
  font-family: $second-font;
  font-size: 55px;
  font-weight: 700;
  text-transform: uppercase;
  @include tablets {
    font-size: 25px;
  }
}

//category-content

.category-content {
  margin: 0 auto;
  max-width: 1030px;
  padding-top: 33px;
}
.category-content__inner {
  margin-right: -25px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.category-content__card {
  display: flex;
  margin-right: 25px;
  flex-direction: column;
  align-items: center;
  max-width: 325px;
  margin-bottom: 25px;
  width: 100%;
  min-height: 231px;
  border: 1px solid #a0a0a0;
  text-decoration: none!important;
  transition: all .25s ease;
  &:hover {
    background-color: gainsboro;
    .category-content__description {
      color: $accent;
    }
    a {
      text-decoration: none;
    }

  }
}

.category-content__icon {
  width: 150px;
  height: 150px;
}

.category-content__description {
  padding-right: 16px;
  padding-left: 16px;
  margin-bottom: 45px;
  font-size: 16px;
  font-weight: 400;
  color: #191919;
  justify-self: center;
  ul {
    padding: initial;
    margin: initial;
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-left: 40px;
  }

  li {
    list-style: circle;
  }
}

.category-content__title {
  display: block;
  margin: 0 auto;
  margin-bottom: 37px;
  padding-top: 15px;
  font-size: 28px;
  font-weight: 700;
  color: $accent;
  text-align: center;
  justify-self: center;
  align-self: center;

}

//content
.only-page {
  padding-top: 45px;
}
.only-page__title {
  margin-bottom: 35px;
  color: #191919;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}
.only-page__content {
  p {
    color: #191919;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 50px;
  }
}

//reviews

.reviews {
  padding-top: 50px;
  padding-bottom: 49px;
}

.reviews__title {
  margin-bottom: 70px;
  color: #191919;
  font-size: 35px;
  font-weight: 900;
  line-height: 1;
  text-align: center;
}

.reviews__card {
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
}

.reviews__header {
  z-index: 100;
  width: 220px;
  font-size: 15px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 44px;
  font-weight: 700;
  color: #ffffff;
  background-color: orangered;
  position: relative;
}

.reviews__avatar {
  position: absolute;
  left: 100%;
  top: -22px;
}

.reviews__description {
  margin-top: -24px;
  padding-top: 94px;
  padding-left: 32px;
  padding-bottom: 32px;
  padding-right: 32px;
  margin-left: 20px;
  background-color: #eeeded;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 39px;
    height: 4px;
    background-color: orangered;
    top: 70px;
  }
}

.reviews__link {
  display: inline-block;
  margin: 0 auto;
  margin-top: 15px;
  padding: 10px 57px;
  background-color: #7f7d7c;
  border-radius: 10px;
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
  color: #ffffff;
  @include btn-hover(#7f7d7c);
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }

}
// works
.works {
  margin: 0 auto;
  margin-bottom: 85px;
  max-width: 832px;

}
.works__inner {
  display: flex;
  flex-wrap: wrap;
  margin-right: -30px;
}

.works__card {
  margin-bottom: 25px;
  margin-right: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 400px;
  background-size: cover;
  cursor: pointer;
  position: relative;
  &:hover {
    text-decoration: none;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($accent, 0.85);
    }
    .works__link {
      display: block;
    }
  }
}

.works__title {
  z-index: 100;
  margin-top: 135px;
  color: white;
  font-size: 25px;
  font-weight: 700;
}

.works__link {
  display: none;
  z-index: 100;
  margin-top: 130px;
  padding: 15px 54px;
  border: 2px solid white;
  border-radius: 10px;
  color: white;
  font-size: 15px;
  font-weight: 300;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
}
